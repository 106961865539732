<template>
  <div class="container">
    <img src="../../assets/sunrise.jpg" />

    <div class="line">
      <div class="label">
        手机号
      </div>
      <div class="field">
        <el-input v-model="phone"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="label">
        验证码
      </div>
      <div class="field">
        <el-input v-model="verifyCode"></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" style="width:110px" :disabled="phoneVerifyBtnDisabled" @click="handleGetCode">
          {{phoneVerifyBtnText}}
        </el-button>
      </div>
    </div>
    <div class="line">
      <div class="login">
        <el-button type="primary" style="width:200px" :loading="loading" @click="handleLogin">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    phoneVerify,
    webbind
  } from "@/api/sunrise"

  export default {
    data() {
      return {
        phone: '',
        verifyCode: '',
        phoneVerifyBtnText: '获取验证码',
        phoneVerifyBtnDisabled: false,
        timerNo: 60,
        timer: null,
        loading: false
      }
    },
    mounted() {
      var query = this.$route.query
      var recommend = typeof(query.recommend) === 'undefined' ? null : query.recommend
      sessionStorage.setItem(process.env.VUE_APP_STORE_RECOMMEND_KEY, recommend)
    },
    methods: {
      init() {
        var membercode = sessionStorage.getItem(process.env.VUE_APP_STORE_MEMBERCODE_KEY)
        if (membercode) {
          this.$router.push('/sunrise/detail');
        }
      },
      handleGetCode() {
        if (!this.phoneValide(this.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            type: 'error',
            duration: 2 * 1000
          })
          return
        }

        this.phoneVerifyBtnDisabled = true
        this.phoneVerifyBtnText = '发送中'

        phoneVerify(this.phone)
          .then(res => {
            if (res.success) {
              this.startTimer()
            } else {
              this.phoneVerifyBtnDisabled = false
              this.phoneVerifyBtnText = '获取验证码'
            }
          })
      },
      phoneValide(phone) {
        if (phone.length != 11)
          return false
        if (phone[0] != '1')
          return false
        return true
      },
      verifyCodeValide(verifyCode) {
        if (verifyCode.length < 6)
          return false
        return true
      },
      startTimer() {
        this.phoneVerifyBtnDisabled = true
        this.timerNo = 60
        this.phoneVerifyBtnText = this.timerNo + '秒后重发'
        this.timer = setTimeout(this.dotimer, 1000);
      },
      stopTimer() {
        clearTimeout(this.timer);
        this.phoneVerifyBtnDisabled = false
        this.phoneVerifyBtnText = '获取验证码'
      },
      dotimer() {
        var newTimeNo = this.timerNo - 1
        if (newTimeNo <= 0) {
          this.stopTimer()
        } else {
          this.timerNo = newTimeNo
          this.phoneVerifyBtnText = this.timerNo + '秒后重发'
          this.timer = setTimeout(this.dotimer, 1000);
        }
      },
      handleLogin() {
        if (!this.phoneValide(this.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            type: 'error',
            duration: 2 * 1000
          })
          return
        }

        if (!this.verifyCodeValide(this.verifyCode)) {
          this.$message({
            message: '请输入正确验证码',
            type: 'error',
            duration: 2 * 1000
          })
          return
        }

        this.loading = true

        webbind(this.phone, this.verifyCode)
          .then(res => {
            this.loading = false
            if (res.success) {
              sessionStorage.setItem(process.env.VUE_APP_STORE_MEMBERCODE_KEY, res.membercode)
              this.$router.push('/sunrise/detail');
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    width: 666px;
    max-width: calc(100vw - 20px);
    margin: 0 auto;

    img {
      max-width: 100%;
      margin-top: 10px;
    }

    .line {
      width: 90%;
      margin: 100px auto 0;
      line-height: 40px;
      display: flex;
      flex-direction: row;

      .label {
        margin-right: 10px;
        min-width: 50px;
      }

      .field {
        flex-grow: 1;

        :deep() .el-input__inner {
          border: 0;
          border-bottom: solid 1px #ccc;
          border-radius: 0px;
        }
      }

      .btn {
        width: 120px;
        text-align: right;
      }

      .login {
        width: 100%;
        text-align: center;
      }
    }

    .line+.line {
      margin-top: 30px;
    }


  }
</style>
